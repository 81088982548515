export const NUMBER_0 = 0;
export const NUMBER_01 = -1;
export const NUMBER_1 = 1;
export const NUMBER_2 = 2;
export const NUMBER_50 = 50;
export const NUMBER_75 = 75;

// 活动状态类型
export const STATUS_0 = 0; // 未启动
export const STATUS_1 = 1; // 进行中
export const STATUS_3 = 3; // 已结束
export const STATUS_4 = 4; // 已归档

// 活动状态的样式类名
export const statusClassName = {
  0: 'survey-status-unstart',
  1: 'survey-status-proceed',
  3: 'survey-status-finish',
  4: 'survey-status-finish',
};

// 活动状态的展示名称
export const activityStatusName = {
  0: '未启动',
  1: '进行中',
  3: '已结束',
  4: '已结束',
};
