import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {
  // 调研活动列表
  pageActivity: async (host, params) => {
    const result = await PageletApi.request(`${host}/${apis.pageActivity}`, {
      method: 'get',
      params: params.data,
      headers: params.headers,
    });

    return result;
  },

  // 删除调研活动
  deleteActivity: async (host, params) => {
    const result = await PageletApi.request(`${host}/${apis.deleteActivity}`, {
      method: 'get',
      params: params.data,
      headers: params.headers,
    });

    return result;
  },
};

export default PageletServices;
