import React, { useEffect, useState } from 'react';
import {
  getLocale,
  Spin,
  Tooltip,
  ConfigProvider,
  List,
  Progress,
  Empty,
  Modal,
  message,
  Ellipse,
} from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import { RightThickOutlined, DeleteOutlined } from '@didi/iconflow/ec';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import InfiniteScroll from 'react-infinite-scroll-component';
import ManagerSurvyeSdk from '@didi/manager-survey-sdk';
import moment from 'moment';
import './style.scoped.less';
import {
  NUMBER_0,
  NUMBER_01,
  NUMBER_1,
  NUMBER_2,
  STATUS_0,
  NUMBER_50,
  NUMBER_75,
  statusClassName,
  activityStatusName,
} from './constants/config';

const localLocales = manifest.locales || {};

const managerSurvey = new ManagerSurvyeSdk();

const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, headers = {} },
  } = props;

  // const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  // const [lastActivityId, setLastActivityId] = useState(null);
  const [current, setCurrent] = useState(NUMBER_1);

  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    functionName: window.encodeURI(auth.functionName),
    'Content-Type': 'application/json',
    ct_role: 'MANAGER_GROUP',
  };

  const getColor = text => {
    if (text < NUMBER_50) {
      return '#ED746F';
    }
    if (text >= NUMBER_50 && text <= NUMBER_75) {
      return '#F5B363';
    }
    if (text > NUMBER_75) {
      return '#55BB96';
    }
  };

  const fetchData = async type => {
    setLoading(true);
    try {
      const res = await PageletServices.pageActivity(apiHost, {
        data: { pageNum: type ? NUMBER_1 : current },
        headers: newHeaders,
      });
      setLoading(false);
      return res?.data?.list;
    } catch (error) {
      setLoading(false);
      window.console.log(error);
      return [];
    }
  };

  const loadMoreData = async () => {
    if (!hasMore) {
      return;
    }
    try {
      const newData = await fetchData();
      if (newData.length < 20) {
        setHasMore(false);
      }
      // 最后一条活动id
      // setLastActivityId(newData[newData.length - 1]?.activityId);
      setCurrent(current + NUMBER_1);
      setData([...data, ...newData]);
    } catch (error) {
      window.console.log(error);
    }
  };

  // 发起调研
  const handleActive = () => {
    managerSurvey.open();
  };

  // 更新数据
  const updatedFetchData = async () => {
    try {
      const updatedData = await fetchData('reset');
      // 删除成功后更新lastActivityId
      // setLastActivityId(updatedData[updatedData.length - 1]?.activityId);
      setCurrent(NUMBER_2);
      setData(updatedData);
      setHasMore(updatedData?.length >= 20);
      // 滚动条回到顶部
      document.getElementsByClassName('survey-infinite-scroll')[0].scrollTop = 0;
    } catch (error) {
      window.console.log(error);
    }
  };

  // 删除调研
  const handleDelete = (e, val) => {
    e.stopPropagation();
    Modal.confirm({
      title: '提示',
      content: '确定要删除该调研吗？',
      okText: '确定',
      cancelText: '取消',
      autoFocusButton: null,
      onOk: async () => {
        try {
          const res = await PageletServices.deleteActivity(apiHost, {
            data: { activityId: val?.activityId },
            headers: newHeaders,
          });
          if (res.code === '100200') {
            message.success('删除成功');
            updatedFetchData();
          }
        } catch (error) {
          window.console.log(error);
        }
      },
      onCancel: () => {},
    });
  };

  // 调研详情
  const handleDetail = val => {
    managerSurvey.open({ activityStatus: val.activityStatus, activityId: val.activityId });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  // 弹窗保存成功后更新数据
  useEffect(() => {
    function handleReceiveMessage(evt) {
      const { type } = evt.data;
      if (type === 'SAVE') {
        updatedFetchData();
      }
    }
    window.addEventListener('message', handleReceiveMessage, false);
    return () => {
      window.removeEventListener('message', handleReceiveMessage, false);
    };
  });

  const prefixCls = 'pagelet-ct-manager-survey';

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  return (
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <Spin spinning={loading}>
          <DHRCardTitle
            title={'调研反馈'}
            titleAdded={
              <DHRCardButton onClick={handleActive}>
                <span className="right-name">发起调研</span>
                <RightThickOutlined size={14} style={{ cursor: 'pointer' }} />
              </DHRCardButton>
            }
            showPrefixTag={false}
          />
          <div id="scrollableDiv">
            {data?.length > 0 ? (
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasMore}
                endMessage={
                  data.length > 2 ? (
                    <div className="survey--more-end">-- 已经是最后一项了 --</div>
                  ) : null
                }
                scrollThreshold={0.8}
                height={148}
                scrollableTarget="scrollableDiv"
                className="survey-infinite-scroll"
              >
                <List
                  split={false}
                  dataSource={data}
                  renderItem={item => (
                    <div
                      key={item.activityId}
                      className="survey-items-box"
                      onClick={() => handleDetail(item)}
                    >
                      <div className="survey-items">
                        <div style={{ marginBottom: '4px' }}>
                          <span className="survey-items-title">
                            <span
                              className={`survey-items-status ${statusClassName[item.activityStatus]}`}
                            >
                              {activityStatusName[item.activityStatus]}
                            </span>
                            <Ellipse text={item.activityName} width={240}>
                              {item.activityName}
                            </Ellipse>
                          </span>
                          {item.activityStatus === STATUS_0 && (
                            <Tooltip title="删除">
                              <span
                                onClick={e => handleDelete(e, item)}
                                className="survey-delete-icon"
                              >
                                <DeleteOutlined cursor="pointer" />
                              </span>
                            </Tooltip>
                          )}
                        </div>
                        <div className="survey-items-bottom">
                          <span className="survey-items-progress">
                            进度：
                            <Progress
                              percent={Number(item?.number?.slice(NUMBER_0, NUMBER_01))}
                              strokeColor={getColor(
                                Number(item?.number?.slice(NUMBER_0, NUMBER_01)),
                              )}
                              format={() => `${item.number}`}
                            />
                          </span>
                          <span className="survey-items-time">
                            截止至：
                            {item.activityStatus === STATUS_0
                              ? '-'
                              : moment(item.activityEndTime).format('YYYY.MM.DD HH:mm')}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </InfiniteScroll>
            ) : (
              <div className="survey-empty">
                <Empty description="暂无调研活动" />
              </div>
            )}
          </div>
        </Spin>
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
